import React, { Fragment } from 'react';  
import { Route } from 'react-router-dom';  

import LoginPage from '../login/Index'

const LoginLayout = ({ children }) => (
    <Fragment>
      {children}
    </Fragment>
); 

const LoginLayoutRoute = ({component: Component, ...rest}) => {  
  return (  
    <Route {...rest} render={matchProps => (  
      <LoginLayout>  
          <LoginPage />
      </LoginLayout>  
    )} />  
  )  
};  
  
export default LoginLayout;  