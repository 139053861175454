import axios from 'axios';
import { configs } from '../configs/configs';

export const getAllZones = () => {
    return axios.get(`${configs.URL_API_BASE}/zones`);
}
export const createNewZone = (data) => {
    return axios.post(`${configs.URL_API_BASE}/zones`, data);
}
export const updateZone = (id, data) => {
    return axios.put(`${configs.URL_API_BASE}/zones/${id}`, data);
}
export const deleteZone = (id) => {
    return axios.delete(`${configs.URL_API_BASE}/zones/${id}`);
} 
