import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';

import { Container } from 'reactstrap';

import Header from '../utils/Header';
import Footer from '../utils/Footer';

const MainLayout = ({children}) => {

    return ( 
        <Fragment>
            <Header />
                <div className="bar-color-top"/>
                <div className=" container-fluid main-content">
                  {children}
                </div>
            <Footer />
        </Fragment>
     );
}

const MainLayoutRoute = ({component: Component, ...rest}) => {  
    return (  
      <Route {...rest} render={matchProps => (  
        <MainLayout>
            <Component {...matchProps} /> 
        </MainLayout>  
      )} />  
    )  
  }; 

export default MainLayout;