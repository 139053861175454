import axios from 'axios';
import { configs } from '../configs/configs';

export const getAllUsers = () => {
    return axios.get(`${configs.URL_API_BASE}/users`);
}
export const createNewUser = (userData) => {
    let form_data = new FormData();
    for (var key in userData) {
        form_data.append(key, userData[key]);
    }
    return axios.post(`${configs.URL_API_BASE}/users`, form_data);
}
export const editUser = (id_user, userData) => {
    let form_data = new FormData();
    for (var key in userData) {
        form_data.append(key, userData[key]);
    }
    return axios.put(`${configs.URL_API_BASE}/users/${id_user}`, form_data);
}
export const getUserById = (id_user) => {
    return axios.get(`${configs.URL_API_BASE}/users/${id_user}`);
}
export const deleteUser = id_user => {
    return axios.delete(`${configs.URL_API_BASE}/users/${id_user}`);
}