import axios from 'axios';
import {configs} from './../configs/configs';

export const getAllTransportUnits = () => {
    return axios.get(`${configs.URL_API_BASE}/units`);
}
export const createNewTransportUnit = (data) => {
    return axios.post(`${configs.URL_API_BASE}/units`,data);
}
export const updateTransportUnit = (id,data) => {
    return axios.put(`${configs.URL_API_BASE}/units/${id}`,data);
}
export const deleteTransportUnit = (id) => {
    return axios.delete(`${configs.URL_API_BASE}/units/${id}`);
} 
export const uploadImage = (data) => {
    return axios.post(`${configs.URL_API_BASE}/files`, data);
}
