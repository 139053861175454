import React from 'react'
import { Container , Row, Col } from 'reactstrap';

const Footer = () => {
    return (
        <footer className={'container-fluid, mt-5, bg-grey'}>
            <Container>
                <Row>
                    <Col xs={12} lg={6}>
                        <p>Transroute Cabo Transportation ©   2020 <br/>Los Cabos, Baja California Sur, México</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer