import React, { Fragment, useState } from 'react'
import { Container, Row, Col, Alert, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay';
import { doLogin } from './../../controllers/auth_controller';

const Index = () => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [loginMessage, setLoginMessage] = useState('Your user or password is not correct')
    const [form, setForm] = useState({
        email: '',
        password: ''
    })

    const handleChange = (event) => {
        setForm({
            ...form,
            [event.target.name] : event.target.value
        });
    }

    const submit = () => {

        if(form.password !== '' && form.email !== ''){

            setLoading(true);
            doLogin({ email: form.email, password: form.password }).then(res => {

                if (res.data.token != null) {
                    setLoading(false);
                    setError(false);
                    setLoginMessage('Your user or password is not correct');

    
                    localStorage.setItem('tr_token', res.data.token);
                    localStorage.setItem('tr_email', res.data.results[0].email);
                    localStorage.setItem('tr_rol', res.data.results[0].id_rol);
                    localStorage.setItem('tr_id', res.data.results[0].id_user);

                    window.location.href = `/reservations`;
    
                } else {
                    setLoading(false);
                    setError(true);
                }
                
            });
        }else{
            setError(true);
            setLoginMessage('Please add email and password parameters')
        }
    }

    return (
        <Fragment>
            

            <Container>
            <LoadingOverlay active={loading} spinner text='Loading ...' >

                <Row className="d-flex justify-content-center">
                    <Col xs={12} md={7} lg={5}>
                        <div className="pt-5 pb-1 mt-auto d-flex justify-content-center">
                            <img width="300" src={process.env.PUBLIC_URL + '/logo192.png'} className="logo-login" alt="RCI" />
                        </div>
                        { 
                            error && <Alert className="alert alert-danger" role="alert">{loginMessage}</Alert>
                        }
                        <div className="login-content p-4 bg-grey">
                            <h3 className="text-dark font-size-30 mb-2 text-center">Login</h3>
                            <Form>
                                <FormGroup>
                                    <Label htmlFor="username">Email</Label>
                                    <Input type="email" onChange={handleChange} name="email" value={form.email} id="email" required/>
                                </FormGroup>
                                <FormGroup>
                                    <Label htmlFor="password">Password</Label>
                                    <Input type="password" onChange={handleChange} name="password" value={form.password} id="password" required />
                                </FormGroup>
                                <Button color="primary" block onClick={submit}>Log in</Button>
                            </Form>
                        </div>
                    </Col>
                </Row>
                
                </LoadingOverlay>
            </Container>

        </Fragment>
    )
}

export default Index;