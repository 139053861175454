import React, { Fragment, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';

import { fab } from '@fortawesome/free-brands-svg-icons'
// Layout
import MainLayout from './components/layout/MainLayoutRoute';
import LoginLayout from './components/layout/LoginLayoutRoute';
// components
import Login from './components/login/Index'
import Reservations from './components/reservations/Index';
import Transport from './components/transport/Index';
import Rates from './components/rates/Index';
import Zones from './components/zones/Index';
import Places from './components/places';
import Users from './components/users';

import {
  faPlaneArrival,
  faPlaneDeparture,
  faCalendarAlt,
  faUsers,
  faUser,
  faSuitcase,
  faRoute,
  faExclamationTriangle,
  faEdit,
  faShuttleVan,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
  faPenAlt,
  faLock,
  faUserFriends,
  faFileInvoiceDollar,
  faSignOutAlt,
  faDownload,
  faPlusCircle,
  faPaperPlane,
  faInfoCircle

} from '@fortawesome/free-solid-svg-icons';

import { library } from '@fortawesome/fontawesome-svg-core';
import { validateTokenFromAPI } from './controllers/auth_controller';

library.add(faSignOutAlt, faInfoCircle, faEdit, faPaperPlane, fab, faSignOutAlt, faDownload, faFileInvoiceDollar, faPlaneArrival, faPlaneDeparture, faCalendarAlt, faUsers, faSuitcase, faRoute, faExclamationTriangle, faShuttleVan, faMapMarkedAlt, faMapMarkerAlt, faUser, faPhone, faEnvelope, faPenAlt, faLock, faUserFriends,faPlusCircle);


function App() {
  return (
    <Router>
      <Switch>
        <PublicRoute exact path='/'>
          <LoginLayout>
            <Login />
          </LoginLayout>
        </PublicRoute>
        <Route path='/reservations' admin={false}>
          <MainLayout>
            <Reservations />
          </MainLayout>
        </Route>
        <Route path='/transports' admin={false}>
          <MainLayout>
            <Transport />
          </MainLayout>
        </Route>
        <Route path='/rates' admin={false}>
          <MainLayout>
            <Rates />
          </MainLayout>
        </Route>
        <Route path='/zones' admin={false}>
          <MainLayout>
            <Zones />
          </MainLayout>
        </Route>
        <Route path='/places' admin={false}>
          <MainLayout>
            <Places />
          </MainLayout>
        </Route>
        <Route path='/users' admin={true}>
          <MainLayout>
            <Users />
          </MainLayout>
        </Route>
      </Switch>
    </Router>
  );
}

const PublicRoute = ({ children, ...rest }) => {
  const [auth, setAuth] = useState(null);

  useEffect(() => {
    if (!localStorage.getItem('tr_token')) {
      localStorage.removeItem('tr_token');
      setAuth(false);
    } else {
      validateTokenFromAPI().then((response) => {
        if (response.data.auth == null || response.data.auth === false) {
          localStorage.removeItem('tr_token');
          setAuth(false);
        } else {
          setAuth(true);
        }
      }).catch((e) => {
        console.log("error", e);
        localStorage.removeItem('tr_token');
        setAuth(false);
      });
    }
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => auth === null ? null :
        auth === false ? (children) : (<Redirect to='/reservations' />)}
    />
  )
}

const PrivateRoute = ({ children, admin, ...rest }) => {
  const [auth, setAuth] = useState(null);

  useEffect(() => {
    if (!localStorage.getItem('tr_token')) {
      localStorage.removeItem('tr_token');
      setAuth(false);
    } else {
      validateTokenFromAPI().then((response) => {
        if (response.data.auth == null || response.data.auth === false) {
          localStorage.removeItem('tr_token');
          setAuth(false);
        } else {
          if (admin === true) {
            parseInt(localStorage.getItem('tr_rol')) === 1 ? setAuth(true) : setAuth(false);
          } else {
            setAuth(true);
          }
        }
      }).catch((e) => {
        console.log("error", e);
        localStorage.removeItem('tr_token');
        setAuth(false);
      });
    }
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => auth === null ? null :
        auth === true ? (children) : (<Redirect to='/' />)}
    />
  )
}

export default App;