import React, { Fragment, useEffect, useState } from 'react';
import Select from 'react-select';
import LoadingOverlay from 'react-loading-overlay';
import Swal from 'sweetalert2';
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import { createNewPlace, deletePlace, getAllPlaces, updatePlace } from '../../controllers/places_controller';
import { getAllZones } from '../../controllers/zones_controller';
import PlacesTable from './PlacesTables';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Index = () => {
    const [placesData, setPlacesData] = useState([]);
    const [placesTableData, setPlacesTableData] = useState([]);
    const [refreshController, setRefreshController] = useState(false);
    const [loaderController, setLoaderController] = useState(false);
    const [modalController, setModalController] = useState(false);
    const [editController, setEditController] = useState(false);

    const [zonesData, setZonesData] = useState([]);
    const [zonesOptionsData, setZonesOptionsData] = useState([]);

    const [formData, setFormData] = useState({
        name: '', label: '', zone_number: '', id_place: ''
    });
    const [placesOptions, setPlacesOptions] = useState([]);
    const [selectValue, setSelectValue] = useState();

    useEffect(()=>{

        getAllZones().then((response) => {
            let responseDataFormated = [];
            let responseOptionsFormated = [];
            response.data.forEach((item) => {
                responseOptionsFormated.push({
                    label: item.label,
                    value: item.id_zone
                });
            });
            response.data.forEach((item) => {
                item.id = item.id_zone;
                item.zone_number = '' + item.zone_number;
                responseDataFormated.push(item);
            });
            setZonesOptionsData(responseOptionsFormated);
            setZonesData(responseDataFormated);

        }).catch((e) => {
            console.log(e);
        })
    }, [])

    useEffect(() => {
        getAllPlaces().then((response) => {
            let responseDataFormated = [];
            let placesSelectOptions = [];
            response.data.forEach((item) => {
                item.id = item.id_place;
                item.zone_number = '' + item.zone_number;
                let isOptionRepeated = false;
                placesSelectOptions.forEach((placeOption) => {
                    if (placeOption.value === parseInt(item.zone_number)) isOptionRepeated = true;
                });
                if (isOptionRepeated === false) {
                    placesSelectOptions.push({
                        value: parseInt(item.zone_number),
                        label: `Zone ${item.zone_number} - ${item.name}`,
                    });
                }
                responseDataFormated.push(item);
            });
            setPlacesOptions(placesSelectOptions);
            setSelectValue(placesSelectOptions[0]);
            setPlacesData(responseDataFormated);
            setPlacesTableData(responseDataFormated.filter((place) => parseInt(place.zone_number) === placesSelectOptions[0].value));
        }).catch((e) => {
            console.log(e);
        });
    }, []);

    useEffect(() => {
        getAllPlaces().then((response) => {
            let responseDataFormated = [];
            let placesSelectOptions = [];
            response.data.forEach((item) => {
                item.id = item.id_place;
                item.zone_number = '' + item.zone_number;
                let isOptionRepeated = false;
                placesSelectOptions.forEach((placeOption) => {
                    if (placeOption.value === parseInt(item.zone_number)) isOptionRepeated = true;
                });
                if (isOptionRepeated === false) {
                    placesSelectOptions.push({
                        value: parseInt(item.zone_number),
                        label: `Zone ${item.zone_number} - ${item.name}`,
                    });
                }
                responseDataFormated.push(item);
            });
            setPlacesOptions(placesSelectOptions);
            setPlacesData(responseDataFormated);
            setPlacesTableData(responseDataFormated.filter((place) => parseInt(place.zone_number) === selectValue.value));
        }).catch((e) => {
            console.log(e);
        });
        setRefreshController(false);
    }, [refreshController]);

    useEffect(() => {
        setPlacesTableData(placesData.filter((place) => parseInt(place.zone_number) === selectValue.value));
    }, [selectValue]);

    const handleSelectChange = (selected) => {
    
        let zone_value_aux = selected.label.split(' ')[1];
        selected.value = parseInt(zone_value_aux);
        setSelectValue(selected);
    }

    const handleFormSelectChange = (selected) => {
        setFormData({
            ...formData,
            zone_number: selected,
        });
    }

    const openModal = () => {
        setEditController(false);
        setModalController(true);
        setFormData({ ...formData, zone_number: placesOptions[0] });
    }

    const toggleModal = () => {
        setModalController(!modalController);
        setFormData({ name: '', label: '', zone_number: placesOptions[0], id_place: '' });
    }

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })
    }

    const handleFormSubmitCreate = (e) => {
        e.preventDefault();
        //console.log("form data",formData, placesData);

        let zone_name_aux = formData.zone_number.label.split('-')[1];
        let zone_number_aux = formData.zone_number.label.split(' ')[1];

        setLoaderController(true);
        let dataToSend = {
            label: formData.name,
            name: zone_name_aux.substring(1),
            zone_number: parseInt(zone_number_aux),
        }

        //console.log('esto inserta', dataToSend )
        createNewPlace(dataToSend).then((res) => {
            setFormData({ name: '', label: '', zone_number: placesOptions[0], id_zone: '' });
            setLoaderController(false)
            Swal.fire('Success!', 'The place has been created successfully.', 'success').then(() => {
                toggleModal();
                setRefreshController(true);
            });
        }).catch((e) => {
            console.log(e);
            Swal.fire('Oops!', 'Something went wrong when creating new place', 'error');
            setLoaderController(false);
        });
    }

    const handleFormSubmitEdit = (e) => {
        e.preventDefault();
        setLoaderController(true);
        let dataToSend = {
            label: formData.name,
            name: placesData.filter((place) => parseInt(place.zone_number) === parseInt(formData.zone_number.value))[0].name,
            zone_number: formData.zone_number.value,
        }
        updatePlace(formData.id_place, dataToSend).then((res) => {
            console.log(res);
            setFormData({ name: '', label: '', zone_number: placesOptions[0], id_place: '' });
            setLoaderController(false)
            Swal.fire('Success!', 'The place has been updated successfully.', 'success').then(() => {
                toggleModal();
                setRefreshController(true);
            });
        }).catch((e) => {
            console.log(e);
            Swal.fire('Oops!', 'Something went wrong when updating place', 'error');
            setLoaderController(false);
        });
    }

    const handleEditZone = (data) => {
        setEditController(true);
        setFormData({
            name: data.label,
            label: `Zone ${data.zone_number} - ${data.name}`,
            zone_number: {
                value: parseInt(data.zone_number),
                label: `Zone ${data.zone_number} - ${data.name}`,
            },
            id_place: data.id_place
        })
        setModalController(true);
    }

    const handleDeleteZone = (zoneData) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "The place information will be deleted!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Accept'
        }).then((result) => {
            if (result.isConfirmed) {
                deletePlace(zoneData.id_place).then(() => {
                    Swal.fire('Success!', 'The place has been deleted successfully.', 'success').then(() => {
                        setRefreshController(true);
                    });
                }).catch((e) => {
                    Swal.fire('Oops!', 'Something went wrong when deleting the selected place', 'error');
                });
            }
        })
    }

    return (
        <Fragment>
            <Container>
                <Row className="py-3">
                    <Col xs={12} className="d-flex align-items-center justify-content-between">
                        <h1>TRANSPORTATION PLACES</h1>
                        <Button color="primary" onClick={openModal}>
                            <FontAwesomeIcon className="mr-2" icon="plus-circle" />Add place
                        </Button>
                    </Col>
                    <Col xs={12} md={6} className="my-4">
                        <label>Select Zone:</label>
                        <Select
                            options={zonesOptionsData}
                            value={selectValue}
                            onChange={handleSelectChange}
                            isSearchable
                            isClearable={false}
                        />
                    </Col>
                    <Col xs={12} className="my-4">
                        <PlacesTable records={placesTableData} deleteHandler={handleDeleteZone} editHandler={handleEditZone} />
                    </Col>
                </Row>
            </Container>
            <Modal isOpen={modalController} size='xl' scrollable={false} >
                <LoadingOverlay active={loaderController} spinner text='Loading...'>
                    <ModalHeader toggle={toggleModal}>
                        {
                            editController ? 'Edit place' : 'Add new place'
                        }
                    </ModalHeader>
                    <form onSubmit={editController ? handleFormSubmitEdit : handleFormSubmitCreate}>
                        <ModalBody>
                            <Row>
                                <Col xs={6} className='my-3'>
                                    <Label>New Zone Name:</Label>
                                    <input className='form-control' type='text' placeholder='Place name' name='name'
                                        onChange={handleInputChange} value={formData.name} required />
                                </Col>
                                <Col xs={6} className='my-3'>
                                    <Label>Add to Zone:</Label>
                                    <Select
                                        options={zonesOptionsData}
                                        value={formData.zone_number}
                                        onChange={handleFormSelectChange}
                                        isSearchable
                                        isClearable={false}
                                    />
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" type='button' onClick={toggleModal}>Cancel</Button>
                            <Button color="primary" type='submit'>{editController ? 'Edit' : 'Add'}</Button>
                        </ModalFooter>
                    </form>
                </LoadingOverlay>
            </Modal>
        </Fragment>
    )
}

export default Index