import React from "react";
import { Button } from 'reactstrap';
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class ReservationsExcelExport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dataSet: props.records,
        }
    }

    static getDerivedStateFromProps = (props, state) => {
        return {
            records: props.records,
        }
    }

    render() {
        return (
            <ExcelFile element={<Button className="button-excell" onClick={() => this.props.controller(false)}>Export Excell</Button>}>
                <ExcelSheet data={this.state.dataSet} name="EXCEL-TR">
                    <ExcelColumn label="ID RESERVATION" value="folio" />
                    <ExcelColumn label="REGISTER" value="createdAt" />
                    <ExcelColumn label="CLIENT" value="fullname" />
                    <ExcelColumn label="ARRIVAL" value="arrival_date" />
                    <ExcelColumn label="FLIGHT NUMBER" value="arrival_flight_number" />
                    <ExcelColumn label="DEPARTURE" value="departure_date" />
                    <ExcelColumn label="UNIT" value="unit" />
                </ExcelSheet>
            </ExcelFile>
        );
    }
}